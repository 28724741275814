/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Otis Admin PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Otis Admin PRO React layouts
import Settings from "pages/settings";
// import PricingPage from "pages/pay";
import PricingPage from "layouts/pages/pricing-page";

// Otis Admin PRO React components
import MDAvatar from "components/MDAvatar";
import DealPlannerList from "pages/deal-plan/list";
import SignIn from "pages/sign-in/illustration";
import SignUp from "pages/sign-up";
// @mui icons
import Icon from "@mui/material/Icon";

// Images
import DealDashboardActiveIcon from "assets/images/small-logos/deal-dashboard-active.svg";
import DealDashboardIcon from "assets/images/small-logos/deal-dashboard-icon.svg";
import DealStrategiesActiveIcon from "assets/images/small-logos/deal-strategies-active.svg";
import DealStrategiesIcon from "assets/images/small-logos/deal-strategies-icon.svg";
import Contact from "pages/contact";
import DealStrategies from "pages/deal-plan/deal-strategies";
import FaqPage from "pages/faq-page";
import PolicyPage from "pages/policy-page";
import ResendEmail from "pages/resend-email";
import ResetPassword from "pages/reset-password";
import TermsPage from "pages/terms-page";
import VerifyEmail from "pages/verify-email";
import DealPlanOperation from "./pages/deal-plan/create-and-edit";
import LandingPage from "./pages/landing-page";

const routes = [
  {
    name: "VerifyEmail",
    key: "verify-email",
    route: "/email/verify",
    component: <VerifyEmail />,
  },
  {
    name: "ResetPassword",
    key: "reset-password",
    route: "/reset-password",
    component: <ResetPassword />,
  },
  {
    name: "Contact",
    key: "contact",
    route: "/contact",
    component: <Contact />,
  },
  {
    name: "Sign In",
    key: "sign-in",
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    name: "Sign Up",
    key: "sign-up",
    route: "/sign-up",
    component: <SignUp />,
  },
  {
    name: "Landing page",
    key: "landing-page",
    route: "/landing-page",
    component: <LandingPage />,
  },
  {
    name: "Policy page",
    key: "policy-page",
    route: "/policy-page",
    component: <PolicyPage />,
  },
  {
    name: "Terms and conditions page",
    key: "terms-conditions-page",
    route: "/terms-conditions-page",
    component: <TermsPage />,
  },
  {
    name: "FAQ",
    key: "faq-page",
    route: "/faq-page",
    component: <FaqPage />,
  },
];

export const privateRoute = [
  {
    name: "VerifyEmail",
    key: "verify-email",
    route: "/email/verify",
    component: <VerifyEmail />,
  },
  {
    name: "Contact",
    key: "contact",
    route: "/contact",
    component: <Contact />,
  },
  {
    name: "ResendEmail",
    key: "resend",
    route: "/resend-email",
    component: <ResendEmail />,
  },
  {
    name: "Policy page",
    key: "policy-page",
    route: "/policy-page",
    component: <PolicyPage />,
  },
  {
    name: "FAQ",
    key: "faq-page",
    route: "/faq-page",
    component: <FaqPage />,
  },
  {
    name: "Terms and conditions page",
    key: "terms-conditions-page",
    route: "/terms-conditions-page",
    component: <TermsPage />,
  },
  {
    name: "Pricing Page",
    key: "pricing-page",
    route: "/pay",
    component: <PricingPage />,
  },
  {
    name: "Create a deal",
    key: "create-a-deal",
    route: "/create-a-deal",
    component: <DealPlanOperation />,
  },
  {
    name: "Edit a deal",
    key: "edit-a-deal",
    route: "/edit-a-deal",
    component: <DealPlanOperation />,
  },
  {
    type: "collapse",
    name: "username",
    key: "username",
    icon: <MDAvatar alt="username" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/my-profile",
        component: <Settings />,
      },
    ],
  },
  {
    type: "collapse-nav",
    name: "Deal Planner",
    key: "deal-planner-menu",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Deal Planner",
        key: "deal-planner",
        route: "/deal-planner",
        iconInactive: DealDashboardIcon,
        iconActive: DealDashboardActiveIcon,
        component: <DealPlannerList />,
      },
      {
        name: "Deal Strategies",
        key: "deal-strategies",
        route: "/deal-strategies",
        iconInactive: DealStrategiesIcon,
        iconActive: DealStrategiesActiveIcon,
        component: <DealStrategies />,
      },
    ],
  },
];

export default routes;
