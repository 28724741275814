/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import { FormControlLabel, IconButton, InputAdornment, RadioGroup, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from "react";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import plusIcon from "assets/images/logos/plus.svg";

// Data
import { Delete } from "@mui/icons-material";
import { FormatNumInput } from "components/FormatNumInput";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDRadio from "components/MDRadio";
import { MoneyInput } from "components/MoneyInput";
import { FieldArray } from "formik";
import numeral from "numeral";
import { useLocation, useNavigate } from "react-router-dom";
import { BehaviorSubject, Subject } from "rxjs";
import { useFlatInject } from "store";
import { redirectUrlAfterSaveSection, submitAutoSave } from "utils/common";
import { useAutosave, useObserver, useObserverState } from "utils/hooks";
import { Message } from "utils/message";
import { Form, FormItem, useForm } from "../../../../../components/Form";
import StrategiesSelect from "../StrategiesSelect";

const radioUI = <MDRadio />;
const fieldStyle = { flex: 1 };
const labelSx = { display: "none" };

const goalPurchasePriceSubject = new Subject();
const totalCashImpactSubject = new BehaviorSubject([0, 0, 0]);
const dealBundleValueSubject = new BehaviorSubject([0, 0, 0]);

const useSyncGoalPrice = (form, name = "goalPurchasePrice") => {
  const flag = useRef(false);

  useEffect(() => {
    if (flag.current === false) {
      return;
    }
    goalPurchasePriceSubject.next(form.values.goalPurchasePrice);
  }, [form.values.goalPurchasePrice]);

  useEffect(() => {
    flag.current = true;
  }, []);
  useObserver(goalPurchasePriceSubject, (newPrice) => {
    if (form.values.goalPurchasePrice !== newPrice) {
      form.setFieldValue(name, newPrice);
    }
  });
};

const BaseForm = forwardRef((_, ref) => {
  const [{ dealBundleWorkSheet }] = useFlatInject("dealPlanOperation", {
    state: [
      (s) => s.dealBundleWorkSheet.askPrice,
      (s) => s.dealBundleWorkSheet.ebitda,
      (s) => s.dealBundleWorkSheet.askMultiple,
    ],
  });
  const form = useForm({
    initialValues: {
      askPrice: dealBundleWorkSheet.askPrice,
      ebitda: dealBundleWorkSheet.ebitda,
      askMultiple: dealBundleWorkSheet.askMultiple,
    },
  });
  useEffect(() => {
    form.setValues({
      askPrice: dealBundleWorkSheet.askPrice,
      ebitda: dealBundleWorkSheet.ebitda,
      askMultiple: dealBundleWorkSheet.askMultiple,
    });
  }, [dealBundleWorkSheet.askPrice, dealBundleWorkSheet.ebitda, dealBundleWorkSheet.askMultiple]);
  useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));
  const sv = totalCashImpactSubject.value;
  if (form.values.askPrice !== sv[0]) {
    totalCashImpactSubject.next([form.values.askPrice || 0, sv[1], sv[2]]);
  }
  const { isMobile } = _;

  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <Grid container spacing="24px">
        <Grid item xs={isMobile ? 6 : 4}>
          <FormItem
            as={MoneyInput}
            name="askPrice"
            variant="standard"
            label="Ask Price"
            fullWidth
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MDTypography variant="button" color="black">
                    $
                  </MDTypography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 4}>
          <FormItem
            as={MoneyInput}
            name="ebitda"
            variant="standard"
            label="EBITDA"
            fullWidth
            disabled
          />
        </Grid>
        <Grid
          item
          xs={isMobile ? 6 : 4}
          sx={{
            marginTop: isMobile ? "16px" : null,
            ...(isMobile && { flexGrow: 1, maxWidth: "100%" }),
          }}
        >
          <FormItem
            as={FormatNumInput}
            name="askMultiple"
            variant="standard"
            label="Ask Multiple"
            fullWidth
            twoDecimal
            disabled
          />
        </Grid>
      </Grid>
    </Form>
  );
});

const PreClosingStrategy = forwardRef((props, ref) => {
  const { isMobile, isSmallMobile } = props;
  const preRef = props?.preClosingAuto;
  const [{ dealBundleWorkSheet, calculateTotal, updateDealBundleValueOfPreClosingStrategy }] =
    useFlatInject("dealPlanOperation", {
      state: ["dealBundleWorkSheet"],
    });
  const [itemMr, setMargin] = React.useState(isMobile || isSmallMobile ? "-22px" : "-32px");
  const { goalPurchasePrice, goalMultiple, table, notes } = dealBundleWorkSheet.preClosingStrategy;
  const form = useForm({
    initialValues: {
      goalPurchasePrice,
      goalMultiple,
      table,
      notes,
    },
    onSubmit: props.submit,
  });
  useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));

  useSyncGoalPrice(form);

  const addMorePreClosing = () => {
    form.setFieldValue("table", [
      ...form.values.table,
      {
        name: "",
        yesOrNo: "",
        value: null,
        notes: "",
      },
    ]);
  };
  const sv = totalCashImpactSubject.value;

  const [askPrice] = useObserverState(totalCashImpactSubject, sv);

  const totalCashImpactPrice = calculateTotal(form.values.table, ["Y", "N"]);
  useEffect(() => {
    // caculate the Goal Purchase Price
    const currentGoalPurchasePrice = Number(form.values.goalPurchasePrice);
    const currentEbitda = dealBundleWorkSheet.ebitda;
    if (currentGoalPurchasePrice && currentEbitda) {
      const currentGoalMultiple = currentGoalPurchasePrice / currentEbitda;
      form.setFieldValue("goalMultiple", currentGoalMultiple);
    }

    updateDealBundleValueOfPreClosingStrategy(calculateTotal(form.values.table));
  }, [
    calculateTotal(form.values.table),
    form.values.goalPurchasePrice,
    dealBundleWorkSheet.ebitda,
  ]);
  const gapLeft = form.values.goalPurchasePrice - calculateTotal(form.values.table);
  if (totalCashImpactPrice !== sv[1]) {
    totalCashImpactSubject.next([sv[0], totalCashImpactPrice || 0, sv[2]]);
  }

  // const formSubmitRef = useRef();

  // useEffect(() => {
  //   formSubmitRef.current = debounce(() => {
  //     form.submitForm();
  //   }, 2000);
  //   return () => {
  //     formSubmitRef.current.flush();
  //   };
  // }, []);

  // // save after user stop typing for 800ms
  // useEffect(() => {
  //   formSubmitRef.current();
  // }, [form.values.goalPurchasePrice]);

  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <MDBox sx={{ display: "flex", padding: "32px 0", gap: "24px" }}>
        <MDBox sx={{ flex: 1 }}>
          <FormItem
            as={MoneyInput}
            name="goalPurchasePrice"
            variant="standard"
            label="Goal Purchase Price"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MDTypography variant="button" color="black">
                    $
                  </MDTypography>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <MDBox sx={{ flex: 1 }}>
          <FormItem
            as={FormatNumInput}
            type="text"
            name="goalMultiple"
            variant="standard"
            label="Goal Multiple"
            fullWidth
            disabled
            twoDecimal
          />
        </MDBox>
      </MDBox>
      <MDTypography fontSize="18px" fontWeight="regular">
        Pre-closing Strategy
      </MDTypography>
      <MDBox py={2}>
        <FieldArray
          name="table"
          render={() => (
            <MDBox>
              {form.values.table.map((one, index) => (
                <Grid
                  container
                  key={`${one.name + index}`}
                  alignItems="center"
                  spacing={4}
                  marginTop={itemMr}
                >
                  <Grid item xs={isMobile ? 12 : 6}>
                    <StrategiesSelect
                      ref={preRef}
                      isSmallMobile={isSmallMobile}
                      isMobile={isMobile}
                      name={`table[${index}].name`}
                      type="preCloseStrategies"
                      setMargin={setMargin}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 5 : 2}
                    sx={{
                      ...(isMobile && {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        paddingTop: "16px !important",
                        gap: "12px",
                      }),
                    }}
                  >
                    <MDTypography
                      variant="caption"
                      fontSize="12px"
                      component="label"
                      color="#282828"
                    >
                      {isMobile ? "Yes / No" : "Used"}
                    </MDTypography>
                    <FormItem
                      as={RadioGroup}
                      row
                      name={`table[${index}].yesOrNo`}
                      formItemSx={{ ...(isMobile && { marginBottom: "0px" }) }}
                    >
                      <Stack
                        direction="row"
                        p={0}
                        m={0}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <FormControlLabel
                          value="Y"
                          control={radioUI}
                          sx={{ ...(isMobile && { display: "flex" }) }}
                          label={
                            isMobile ? (
                              <MDTypography
                                component="label"
                                sx={{
                                  marginLeft: isSmallMobile ? "0px" : "5px",
                                  fontSize: "16px",
                                  color: "#141414",
                                }}
                              >
                                Yes
                              </MDTypography>
                            ) : (
                              "Yes"
                            )
                          }
                        />
                        <FormControlLabel
                          value="N"
                          control={radioUI}
                          sx={{ ...(isMobile && { display: "flex" }) }}
                          label={
                            isMobile ? (
                              <MDTypography
                                component="label"
                                sx={{
                                  marginLeft: isSmallMobile ? "0px" : "5px",
                                  fontSize: "16px",
                                  color: "#141414",
                                }}
                              >
                                No
                              </MDTypography>
                            ) : (
                              "No"
                            )
                          }
                        />
                      </Stack>
                    </FormItem>
                  </Grid>
                  <Grid item xs={isMobile ? 5 : 3}>
                    <FormItem
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MDTypography variant="button" color="black">
                              $
                            </MDTypography>
                          </InputAdornment>
                        ),
                      }}
                      as={MoneyInput}
                      label="Value"
                      name={`table[${index}].value`}
                    />
                  </Grid>
                  <Grid item xs={isMobile ? 2 : 1}>
                    <IconButton
                      onClick={() => {
                        form.setValues({
                          table: form.values.table.filter((i) => i !== one),
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} marginTop={itemMr}>
                    <FormItem
                      as={MDInput}
                      placeholder="Information entered here will be added as a term into the Deal Term Sheet"
                      label="Enter notes to populate into Term sheet"
                      multiline
                      type="text"
                      name={`table[${index}].notes`}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ))}
            </MDBox>
          )}
        />
      </MDBox>
      <MDBox>
        <MDButton color="green" variant="outlined" onClick={addMorePreClosing}>
          <MDBox component="img" src={plusIcon} /> &nbsp;Add more
        </MDButton>
        {!isMobile && (
          <MDBox width="590px" py={4}>
            <MDInput
              label="Total Cash Impact"
              sx={{ width: 203 }}
              value={numeral(totalCashImpactPrice).format("0,0")}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MDTypography variant="button" color="black">
                      $
                    </MDTypography>
                  </InputAdornment>
                ),
              }}
            />
          </MDBox>
        )}
        <Grid
          container
          spacing={isMobile ? 2 : 0}
          sx={{ paddingBottom: "32px", marginTop: "16px" }}
        >
          {isMobile && (
            <Grid item xs={isMobile ? 6 : 4}>
              <MDInput
                label="Total Cash Impact"
                value={numeral(totalCashImpactPrice).format("0,0")}
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MDTypography variant="button" color="black">
                        $
                      </MDTypography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          <Grid item xs={isMobile ? 6 : 4}>
            <FormItem
              as={MoneyInput}
              multiline
              name="goalPurchasePrice"
              variant="standard"
              label="Goal Purchase Price"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MDTypography variant="button" color="black">
                      $
                    </MDTypography>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 4}>
            <MDInput
              multiline
              type="number"
              name="dealBundleValue"
              variant="standard"
              label="Deal Bundle Value"
              value={numeral(calculateTotal(form.values.table)).format("0,0")}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MDTypography variant="button" color="black">
                      $
                    </MDTypography>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 4}>
            <MDInput
              multiline
              type="number"
              name="gapLeft"
              variant="standard"
              label="Gap Left"
              fullWidth
              value={numeral(gapLeft).format("0,0")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MDTypography variant="button" color="black">
                      $
                    </MDTypography>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <FormItem
          as={MDInput}
          placeholder="Enter Notes"
          multiline
          type="text"
          name="notes"
          variant="standard"
          fullWidth
          label="Notes"
        />
      </MDBox>
      {/* <MDBox sx={{ flex: 1 }}>
        <FormItem
          as={MDInput}
          multiline
          type="text"
          value={calculateTotal(form.values.table)}
          name="goalMultiple"
          variant="standard"
          label="Goal Multiple"
          fullWidth
        />
      </MDBox> */}
      <MDTypography sx={{ paddingTop: "32px" }} fontSize="18px" fontWeight="regular">
        Gap Funding Strategy
      </MDTypography>
    </Form>
  );
});

const GapFundingStrategy = forwardRef((props, ref) => {
  const { isMobile, isSmallMobile } = props;
  const [itemMr, setMargin] = React.useState(isMobile || isSmallMobile ? "-20px" : "-32px");
  const gapAutoRef = props?.gapClosingAuto;
  const [
    {
      dealBundleWorkSheet,
      calculateTotal,
      updateDealBundleValueOfGapFundingStrategy,
      dealBundleWorkSheetRunningCache,
    },
  ] = useFlatInject("dealPlanOperation", {
    state: [
      "dealBundleWorkSheet.gapFundingStrategy",
      "dealBundleWorkSheetRunningCache.dealBundleValue.preClosingStrategy",
    ],
  });
  const { goalPurchasePrice, dealBundleValue, gapLeft, table, notes } =
    dealBundleWorkSheet.gapFundingStrategy;
  const form = useForm({
    initialValues: {
      goalPurchasePrice,
      dealBundleValue,
      gapLeft,
      table,
      notes,
    },
  });
  useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));

  useSyncGoalPrice(form);

  const addMoreGapFunding = () => {
    form.setFieldValue("table", [
      ...form.values.table,
      {
        name: "",
        yesOrNo: "",
        value: null,
        notes: "",
      },
    ]);
  };

  const sv = totalCashImpactSubject.value;

  const [, restPrice] = useObserverState(totalCashImpactSubject, sv);

  const totalCashImpactPrice = calculateTotal(form.values.table, ["Y", "N"]);

  const dealBoundleValue =
    calculateTotal(form.values.table) +
    dealBundleWorkSheetRunningCache.dealBundleValue.preClosingStrategy;

  if (totalCashImpactPrice !== sv[2]) {
    totalCashImpactSubject.next([sv[0], sv[1], totalCashImpactPrice || 0]);
  }

  useEffect(() => {
    updateDealBundleValueOfGapFundingStrategy(dealBoundleValue);
  }, [dealBoundleValue]);

  useEffect(() => {
    const value = numeral(form.values.goalPurchasePrice - dealBoundleValue).format("0,0");
    const bundleValue = numeral(dealBoundleValue).format("0,0");
    form.setFieldValue("gapLeft", value);
    form.setFieldValue("dealBundleValue", bundleValue);
  }, [form.values.goalPurchasePrice, dealBoundleValue]);

  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <MDBox py={2}>
        <FieldArray
          name="table"
          render={() => (
            <MDBox>
              {form.values.table.map((one, index) => (
                <Grid
                  container
                  key={`${one.name + index}`}
                  alignItems="center"
                  spacing={4}
                  marginTop={itemMr}
                >
                  <Grid item xs={isMobile ? 12 : 6}>
                    <StrategiesSelect
                      ref={gapAutoRef}
                      isMobile={isMobile}
                      isSmallMobile={isSmallMobile}
                      name={`table[${index}].name`}
                      type="gapFundingStrategies"
                      setMargin={setMargin}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 5 : 2}
                    sx={{
                      ...(isMobile && {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        paddingTop: "16px !important",
                        gap: "12px",
                      }),
                    }}
                  >
                    <MDTypography
                      variant="caption"
                      component="label"
                      fontSize="12px"
                      color="#282828"
                    >
                      {isMobile ? "Yes / No" : "Used"}
                    </MDTypography>
                    <FormItem
                      as={RadioGroup}
                      row
                      name={`table[${index}].yesOrNo`}
                      formItemSx={{ ...(isMobile && { marginBottom: "0px" }) }}
                    >
                      <Stack direction="row">
                        <FormControlLabel
                          value="Y"
                          control={radioUI}
                          sx={{ ...(isMobile && { display: "flex" }) }}
                          label={
                            isMobile ? (
                              <MDTypography
                                component="label"
                                sx={{
                                  marginLeft: isSmallMobile ? "0px" : "5px",
                                  fontSize: "16px",
                                  color: "#141414",
                                }}
                              >
                                Yes
                              </MDTypography>
                            ) : (
                              "Yes"
                            )
                          }
                        />
                        <FormControlLabel
                          value="N"
                          control={radioUI}
                          sx={{ ...(isMobile && { display: "flex" }) }}
                          label={
                            isMobile ? (
                              <MDTypography
                                component="label"
                                sx={{
                                  marginLeft: isSmallMobile ? "0px" : "5px",
                                  fontSize: "16px",
                                  color: "#141414",
                                }}
                              >
                                No
                              </MDTypography>
                            ) : (
                              "No"
                            )
                          }
                        />
                      </Stack>
                    </FormItem>
                  </Grid>
                  <Grid item xs={isMobile ? 5 : 3}>
                    <FormItem
                      as={MoneyInput}
                      variant="standard"
                      name={`table[${index}].value`}
                      label="Value"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MDTypography variant="button" color="black">
                              $
                            </MDTypography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={isMobile ? 2 : 1}>
                    <IconButton
                      onClick={() => {
                        // form.setValues({
                        //   table: form.values.table.filter((i) => i !== one),
                        // });
                        form.setFieldValue("table", [
                          ...form.values.table.filter((i) => i !== one),
                        ]);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} marginTop={itemMr}>
                    <FormItem
                      as={MDInput}
                      placeholder="Information entered here will be added as a term into the Deal Term Sheet"
                      label="Enter notes to populate into Term sheet"
                      multiline
                      type="text"
                      name={`table[${index}].notes`}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ))}
            </MDBox>
          )}
        />
      </MDBox>
      <MDBox>
        <MDButton color="green" variant="outlined" onClick={addMoreGapFunding}>
          <MDBox component="img" src={plusIcon} /> &nbsp;Add more
        </MDButton>
        {!isMobile && (
          <MDBox width="590px" py={4}>
            <MDInput
              label="Total Gap Funded"
              sx={{ width: 203 }}
              value={numeral(totalCashImpactPrice).format("0,0")}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MDTypography variant="button" color="black">
                      $
                    </MDTypography>
                  </InputAdornment>
                ),
              }}
            />
          </MDBox>
        )}

        <Grid
          container
          spacing={isMobile ? 2 : 0}
          sx={{ paddingBottom: "32px", ...(isMobile && { marginTop: "16px" }) }}
        >
          {isMobile && (
            <Grid item xs={isMobile ? 6 : 4}>
              <MDInput
                label="Total Gap Funded"
                value={numeral(totalCashImpactPrice).format("0,0")}
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MDTypography variant="button" color="black">
                        $
                      </MDTypography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          <Grid item xs={isMobile ? 6 : 4}>
            <FormItem
              as={MoneyInput}
              name="goalPurchasePrice"
              variant="standard"
              label="Goal Purchase Price"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MDTypography variant="button" color="black">
                      $
                    </MDTypography>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 4}>
            <MDInput
              multiline
              type="number"
              name="dealBundleValue"
              variant="standard"
              label="Deal Bundle Value"
              fullWidth
              value={numeral(dealBoundleValue).format("0,0")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MDTypography variant="button" color="black">
                      $
                    </MDTypography>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 4}>
            <MDInput
              multiline
              type="number"
              name="gapLeft"
              variant="standard"
              label="Gap Left"
              fullWidth
              value={numeral(form.values.goalPurchasePrice - dealBoundleValue).format("0,0")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MDTypography variant="button" color="black">
                      $
                    </MDTypography>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <FormItem
          as={MDInput}
          placeholder="Enter Notes"
          label="Notes"
          multiline
          type="text"
          name="notes"
          variant="standard"
          fullWidth
        />
      </MDBox>
      <MDBox pt={4}>
        <MDTypography fontSize="18px" fontWeight="regular">
          Post-closing Strategy
        </MDTypography>
      </MDBox>
    </Form>
  );
});

const PostClosingStrategy = forwardRef((_, ref) => {
  const postRefAuto = _?.postClosingAuto;
  const [{ dealBundleWorkSheet, calculateTotal }] = useFlatInject("dealPlanOperation", {
    state: ["dealBundleWorkSheet.postClosingStrategy"],
  });
  const { isMobile, isSmallMobile } = _;
  const [itemMr, setMargin] = React.useState(isMobile || isSmallMobile ? "-22px" : "-32px");
  const { goalPurchasePrice, dealBundleValue, gapLeft, table, notes } =
    dealBundleWorkSheet.postClosingStrategy;
  const form = useForm({
    initialValues: {
      goalPurchasePrice,
      dealBundleValue,
      gapLeft,
      table,
      notes,
    },
  });
  useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));
  useSyncGoalPrice(form);

  const addMorePostClosing = () => {
    form.setFieldValue("table", [
      ...form.values.table,
      {
        name: "",
        yesOrNo: "",
        value: null,
        notes: "",
      },
    ]);
  };

  const sv = totalCashImpactSubject.value;
  const [, , restPrice] = useObserverState(totalCashImpactSubject, sv);

  const totalCashImpactPrice = calculateTotal(form.values.table, ["Y", "N"]);

  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <MDBox py={2}>
        <FieldArray
          name="table"
          render={() => (
            <MDBox>
              {form.values.table.map((one, index) => (
                <Grid
                  container
                  key={`${one.name + index}`}
                  alignItems="center"
                  spacing={4}
                  marginTop={itemMr}
                >
                  <Grid item xs={isMobile ? 12 : 6}>
                    <StrategiesSelect
                      ref={postRefAuto}
                      isMobile={isMobile}
                      isSmallMobile={isSmallMobile}
                      name={`table[${index}].name`}
                      type="postCloseStrategies"
                      setMargin={setMargin}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 5 : 2}
                    sx={{
                      ...(isMobile && {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        paddingTop: "16px !important",
                        gap: "12px",
                      }),
                    }}
                  >
                    <MDTypography
                      variant="caption"
                      fontSize="12px"
                      component="label"
                      color="#282828"
                    >
                      {isMobile ? "Yes / No" : "Used"}
                    </MDTypography>
                    <FormItem
                      as={RadioGroup}
                      row
                      name={`table[${index}].yesOrNo`}
                      formItemSx={{ ...(isMobile && { marginBottom: "0px" }) }}
                    >
                      <Stack direction="row">
                        <FormControlLabel
                          value="Y"
                          sx={{ ...(isMobile && { display: "flex" }) }}
                          control={radioUI}
                          label={
                            isMobile ? (
                              <MDTypography
                                component="label"
                                sx={{
                                  marginLeft: isSmallMobile ? "0px" : "5px",
                                  fontSize: "16px",
                                  color: "#141414",
                                }}
                              >
                                Yes
                              </MDTypography>
                            ) : (
                              "Yes"
                            )
                          }
                        />
                        <FormControlLabel
                          value="N"
                          control={radioUI}
                          sx={{ ...(isMobile && { display: "flex" }) }}
                          label={
                            isMobile ? (
                              <MDTypography
                                component="label"
                                sx={{
                                  marginLeft: isSmallMobile ? "0px" : "5px",
                                  fontSize: "16px",
                                  color: "#141414",
                                }}
                              >
                                No
                              </MDTypography>
                            ) : (
                              "No"
                            )
                          }
                        />
                      </Stack>
                    </FormItem>
                  </Grid>
                  <Grid item xs={isMobile ? 5 : 3}>
                    <FormItem
                      as={MoneyInput}
                      variant="standard"
                      name={`table[${index}].value`}
                      label="Value"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MDTypography variant="button" color="black">
                              $
                            </MDTypography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={isMobile ? 2 : 1}>
                    <IconButton
                      onClick={() => {
                        form.setValues({
                          table: form.values.table.filter((i) => i !== one),
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} marginTop={itemMr}>
                    <FormItem
                      as={MDInput}
                      placeholder="Enter notes"
                      label="Notes"
                      multiline
                      type="text"
                      name={`table[${index}].notes`}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ))}
            </MDBox>
          )}
        />
      </MDBox>
      <MDBox>
        <MDButton color="green" variant="outlined" onClick={addMorePostClosing}>
          <MDBox component="img" src={plusIcon} /> &nbsp;Add more
        </MDButton>
        <MDBox width={isMobile ? "100%" : "590px"} py={4}>
          <MDInput
            label="Total Cash Impact"
            sx={{ width: isMobile ? "100%" : 203 }}
            value={numeral(totalCashImpactPrice).format("0,0")}
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MDTypography variant="button" color="black">
                    $
                  </MDTypography>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <FormItem
          as={MDInput}
          placeholder="Enter Notes"
          label="Notes"
          multiline
          type="text"
          name="notes"
          variant="standard"
          fullWidth
        />
      </MDBox>
    </Form>
  );
});

const DealNotesForm = forwardRef((_, ref) => {
  const [{ dealBundleWorkSheet, dealReview }] = useFlatInject("dealPlanOperation");
  const form = useForm({
    initialValues: dealBundleWorkSheet.dealNotes,
    onSubmit: _.submit,
  });
  useSyncGoalPrice(form, "bizImpact");
  useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile } = _;

  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <MDBox pt={3}>
        <MDTypography fontSize="18px" fontWeight="regular">
          Empire Impact
        </MDTypography>
      </MDBox>
      <Grid sx={12} container rowSpacing={isMobile ? 2 : 1} columnSpacing={{ xs: 2, sm: 2 }} my={3}>
        <Grid item xs={isMobile ? 6 : 4}>
          <FormItem
            as={MoneyInput}
            label="Net Value Added"
            name="netValueAdded"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 4}>
          <FormItem
            as={MoneyInput}
            label="Annual Positive Cash Added"
            name="annualPositiveCashAdded"
            variant="standard"
            fullWidth
          />
        </Grid>
        {/* <Grid item xs={3}>
          <FormItem
            as={SelectWithLabel}
            type="text"
            name="fullyFundedOrPartiallyFunded"
            variant="standard"
            fullWidth
            placeholder="Select option"
            label="Fully Funded or Partially Funded"
          >
            <MenuItem value="Fully">1 - Fully</MenuItem>
            <MenuItem value="Partially">2 - Partially</MenuItem>
          </FormItem>
        </Grid> */}
        <Grid item xs={isMobile ? 6 : 4} sx={{ padding: isMobile ? "" : "0 0 0 16px !important" }}>
          <MDTypography
            sx={{ color: "#282828 !important", marginBottom: "8px", fontSize: "12px" }}
            fontSize={12}
          >
            Fully Funded
          </MDTypography>
          <FormItem as={RadioGroup} row name="yesOrNo">
            <Stack direction="row">
              <FormControlLabel
                value="Y"
                control={radioUI}
                sx={{ ...(isMobile && { display: "flex" }) }}
                label={
                  isMobile ? (
                    <MDTypography
                      fontWeight="regular"
                      sx={{ color: "#141414 !important", fontSize: "16px" }}
                    >
                      Yes
                    </MDTypography>
                  ) : (
                    "Yes"
                  )
                }
              />
              <FormControlLabel
                value="N"
                control={radioUI}
                sx={{ ...(isMobile && { display: "flex" }) }}
                label={
                  isMobile ? (
                    <MDTypography
                      fontWeight="regular"
                      sx={{ color: "#141414 !important", fontSize: "16px" }}
                    >
                      No
                    </MDTypography>
                  ) : (
                    "No"
                  )
                }
              />
            </Stack>
          </FormItem>
          <MDBox component="div" sx={{ width: "100%" }} />
        </Grid>
      </Grid>
      <FormItem
        as={MDInput}
        multiline
        type="text"
        name="notes"
        variant="standard"
        fullWidth
        label="Notes"
        placeholder="Enter Notes"
      />
      <Grid mt={4} item xs={12} textAlign="right" display="flex" justifyContent="end">
        <MDButton
          color="green"
          variant="outlined"
          onClick={() => navigate("/deal-planner", { replace: true })}
          sx={{
            mr: 1,
            fontSize: "14px",
          }}
        >
          Cancel
        </MDButton>
        <MDButton
          loading={form.isSubmitting}
          disabled={form.isSubmitting || !dealReview.businessName}
          onClick={form.submitForm}
          color="green"
          sx={{
            border: "1px solid #004225",
            fontSize: "14px",
          }}
        >
          Save and next
        </MDButton>
      </Grid>
    </Form>
  );
});

function DealBundleWorkSheet({ isMobile, isSmallMobile, setTextAutoSave, setSavedSection }) {
  const [{ saveDealBundleWorkSheet, setCardOpen, baseInfo }] = useFlatInject("dealPlanOperation");
  const baseFormRef = useRef();
  const location = useLocation();
  const preClosingFormRef = useRef();
  const gapFundingFormRef = useRef();
  const postClosingFormRef = useRef();
  const dealNotesFormRef = useRef();

  const preClosingAuto = useRef();
  const gapClosingAuto = useRef();
  const postClosingAuto = useRef();
  const submit = useCallback(
    async (isAuto = false) => {
      const baseFormData = baseFormRef.current?.getFormData?.();
      const preClosingFormData = preClosingFormRef.current?.getFormData?.();
      const gapFundingFormData = gapFundingFormRef.current?.getFormData?.();
      const postClosingFormData = postClosingFormRef.current?.getFormData?.();
      const dealNotesFormData = dealNotesFormRef.current?.getFormData?.();

      try {
        await saveDealBundleWorkSheet({
          ...baseFormData,
          preClosingStrategy: {
            ...preClosingFormData,
            table: preClosingFormData.table,
          },
          gapFundingStrategy: {
            ...gapFundingFormData,
            table: gapFundingFormData.table,
          },
          postClosingStrategy: {
            ...postClosingFormData,
            table: postClosingFormData.table,
          },
          dealNotes: dealNotesFormData,
        });
        if (!isAuto) {
          Message.success("Changes saved");
          redirectUrlAfterSaveSection(
            setSavedSection,
            `${location.pathname + location.search}#deal-pitch`
          );
        }
        // setCardOpen("deal-pitch");
      } catch (error) {
        Message.error(error.message);
      }
    },
    [saveDealBundleWorkSheet]
  );

  useAutosave(async () => {
    if (baseInfo?.id) {
      submitAutoSave(() => submit(true), setTextAutoSave);
    }
  });
  return (
    <Card
      id="deal-bundle-work-sheet"
      sx={{
        overflow: "visible",
        boxShadow: isMobile ? "none" : null,
      }}
    >
      {!isMobile && (
        <MDBox p={3}>
          <MDTypography variant="h4" fontWeight="regular">
            Deal Bundle Worksheet
          </MDTypography>
        </MDBox>
      )}
      <MDBox component="form" pb={3} px={isMobile ? 0 : 3}>
        <BaseForm isMobile={isMobile} ref={baseFormRef} />
        <PreClosingStrategy
          isMobile={isMobile}
          ref={preClosingFormRef}
          submit={submit}
          isSmallMobile={isSmallMobile}
          preClosingAuto={preClosingAuto}
        />
        <GapFundingStrategy
          isMobile={isMobile}
          ref={gapFundingFormRef}
          isSmallMobile={isSmallMobile}
          gapClosingAuto={gapClosingAuto}
        />
        <PostClosingStrategy
          isMobile={isMobile}
          ref={postClosingFormRef}
          isSmallMobile={isSmallMobile}
          postClosingAuto={postClosingAuto}
        />
        <DealNotesForm isMobile={isMobile} submit={() => submit(false)} ref={dealNotesFormRef} />
      </MDBox>
    </Card>
  );
}

export default DealBundleWorkSheet;
