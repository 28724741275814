/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components

// Data
import { styled } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import numeral from "numeral";
import { useFlatInject } from "store";
import { useForm, Form, FormItem } from "../../../../../components/Form";

const ZebraPrintPWarp = styled(MDBox)`
  width: 100%;
  .MuiGrid-root {
    padding: 0;
    // margin: 20px 0 20px 20px;
  }
  p {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px 0px;
  }
`;

export const ZebraPrintBoxWarp = styled(MDBox)`
  width: 100%;
  border: 1px solid #ccc;
  padding: 0 !important;
  margin: 20px;
  & > *:nth-child(odd) {
    background-color: rgb(242, 242, 242);
  }
  & > * {
    padding: 20px 20px;
  }
  &,
  .MuiGrid-item {
  }
  /* .MuiGrid-root {
    border: 1px solid #ccc;
    padding: 0;
    margin: 20px;
    .MuiBox-root:nth-child(odd) {
      background-color: rgb(242, 242, 242);
    }
    .MuiBox-root {
      padding: 20px 20px;
    }
    p:nth-child(odd) {
      background-color: rgb(242, 242, 242);
    }
    p {
      padding: 20px 20px;
    }
  } */
`;

const yesOrNo = {
  Y: "Yes",
  N: "No",
};
function Item({ name, value, isMobile }) {
  return isMobile ? (
    <MDTypography color="#282828" fontSize={12} sx={{ color: "#282828" }}>
      {name}
      <p
        style={{
          color: "#282828",
          fontSize: 16,
          padding: 0,
          border: "none",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {value}
      </p>
    </MDTypography>
  ) : (
    <MDTypography color="#282828" fontSize={14} display="flex" id="item-multiline">
      <p style={{ minWidth: "300px", borderBottom: "none", padding: 0 }}>{name}</p>{" "}
      <span
        style={{
          color: "#282828",
          fontSize: 16,
        }}
      >
        {value}
      </span>
    </MDTypography>
  );
}

function Summery({ isMobile }) {
  const [
    {
      dealReview,
      dealAnalysis,
      dealBundleWorkSheet,
      preClosingStrategyTable,
      gapFundingStrategyTable,
      postClosingStrategyTable,
      calculateTotal,
      nextStep,
    },
  ] = useFlatInject("dealPlanOperation");
  console.log("dealBundleWorkSheet", dealBundleWorkSheet.editda);
  return (
    <Card
      id="deal-pitch"
      sx={{
        overflow: "visible",
        boxShadow: isMobile ? "none" : null,
      }}
    >
      {!isMobile && (
        <MDBox p={3}>
          <MDTypography variant="h4" fontWeight="regular">
            Deal Summary
          </MDTypography>
        </MDBox>
      )}
      <MDBox pb={3} px={isMobile ? 0 : 3}>
        <Grid container spacing={3}>
          <ZebraPrintPWarp>
            <MDBox px={isMobile ? 2 : 3} pt={3} width="100%">
              <MDTypography
                variant="h6"
                sx={{
                  fontSize: {
                    lg: "1rem",
                    md: "1rem",
                    sm: "0.75rem",
                    xs: "18px",
                  },
                  color: "#282828",
                }}
                fontWeight="medium"
              >
                Deal Overview
              </MDTypography>
            </MDBox>
            <MDBox px={isMobile ? 2 : 3}>
              <MDTypography
                variant="h6"
                color="green"
                sx={{
                  fontSize: {
                    lg: "0.9rem",
                    md: "0.9rem",
                    sm: "0.6rem",
                    xs: "16px",
                  },
                }}
                fontWeight="regular"
              >
                Pre Meet
              </MDTypography>
            </MDBox>
            <Grid
              item
              xs={12}
              px={isMobile ? 2 : 3}
              sx={{ margin: isMobile ? "20px 0 20px 20px" : "20px 0 20px 24px" }}
            >
              <Item
                name="Product / Service Overview"
                value={dealReview.productAndServiceOverview}
                isMobile={isMobile}
              />
              <Item name="Industry Info" value={dealReview.industryInfo} isMobile={isMobile} />
              <Item name="Timing" value={dealReview.timing} isMobile={isMobile} />
              <Item
                name="Industry Multiple"
                value={dealReview.industryMultiplie}
                isMobile={isMobile}
              />
              <Item
                name="Connection In Industry"
                value={dealReview.connectionInIndustry}
                isMobile={isMobile}
              />
              <Item name="Impact To Empire" value={dealReview.impactToEmpire} isMobile={isMobile} />
            </Grid>
            <MDBox px={isMobile ? 2 : 3} pt={3}>
              <MDTypography
                variant="h6"
                sx={{
                  fontSize: {
                    lg: "1rem",
                    md: "1rem",
                    sm: "0.75rem",
                    xs: "18px",
                  },
                  color: "#282828",
                }}
                fontWeight="medium"
              >
                Deal Overview
              </MDTypography>
              <MDTypography
                variant="h6"
                color="green"
                sx={{
                  fontSize: {
                    lg: "0.9rem",
                    md: "0.9rem",
                    sm: "0.6rem",
                    xs: "16px",
                  },
                }}
                fontWeight="regular"
              >
                First Meet Criticals
              </MDTypography>
            </MDBox>
            <Grid item xs={12} sx={{ margin: isMobile ? "20px 0 20px 20px" : "20px 0 20px 24px" }}>
              <Item
                name="Motivation To Sell"
                value={dealReview.motivationToSell}
                isMobile={isMobile}
              />
              <Item
                name="Their Personal Story"
                value={dealReview.theirPersonalStory}
                isMobile={isMobile}
              />
              <Item
                name="The Business Story"
                value={dealReview.theBusinessStory}
                isMobile={isMobile}
              />
              <Item
                name="Years In Business"
                value={dealReview.yearsInBusiness}
                isMobile={isMobile}
              />
              <Item name="Owner Is A" value={dealReview.ownerIsA} isMobile={isMobile} />
              <Item name="Number Of Team" value={dealReview.numberOfTeam} isMobile={isMobile} />
              <Item
                name="Going Concern?"
                value={dealReview.goingConcernTrigger}
                isMobile={isMobile}
              />
              <Item
                name="What's great about the business?"
                value={dealReview.goingConcern}
                isMobile={isMobile}
              />
              <Item
                name="What's Challenging About The Business"
                value={dealReview.businessChallenge}
                isMobile={isMobile}
              />
              {/* numeral(dealBundleWorkSheet.gapFundingStrategy.gapLeft).format("0,0") */}
              {/* dealReview.avgMonthlyTurnOver */}
              <Item
                name="Avg. Monthly Turnover"
                value={`$${numeral(dealReview.avgMonthlyTurnOver).format("0,0")}`}
                isMobile={isMobile}
              />
              <Item
                name="Avg. Monthly Expenses"
                value={`$${numeral(dealReview.avgMonthlyExpenses).format("0,0")}`}
                isMobile={isMobile}
              />
              <Item
                name="Avg. Monthly Profit"
                value={`$${numeral(dealReview.AverageMonthlyProfit).format("0,0")}`}
                isMobile={isMobile}
              />
              <Item
                name="Timeframe To Sell"
                value={dealReview.timeFramesSell}
                isMobile={isMobile}
              />
              <Item
                name="Any Other Leverage Points"
                value={dealReview.anyOtherLeveragePoints}
                isMobile={isMobile}
              />
              <Item name="Deal Notes" value={dealReview.dealReviewNotes} isMobile={isMobile} />
            </Grid>

            {/* <MDBox px={3} pt={3} width="100%">
              <MDTypography variant="h6">Deal Analysis</MDTypography>
            </MDBox>
            <MDBox px={3} pt={3}>
              <MDTypography
                variant="h6"
                color="green"
                sx={{
                  fontWeight: {
                    lg: 700,
                    md: 700,
                    sm: 700,
                    xs: 500,
                  },
                }}
              >
                Business Overview
              </MDTypography>
            </MDBox> */}
            <MDBox px={isMobile ? 2 : 3} pt={3}>
              <MDTypography
                variant="h6"
                sx={{
                  fontSize: {
                    lg: "1rem",
                    md: "1rem",
                    sm: "0.75rem",
                    xs: "18px",
                  },
                  color: "#282828",
                }}
                fontWeight="medium"
              >
                Deal Analysis
              </MDTypography>
              <MDTypography
                variant="h6"
                color="green"
                sx={{
                  fontSize: {
                    lg: "0.9rem",
                    md: "0.9rem",
                    sm: "0.6rem",
                    xs: "16px",
                  },
                }}
                fontWeight="regular"
              >
                Business Overview
              </MDTypography>
            </MDBox>
            <Grid item xs={12} sx={{ margin: isMobile ? "20px 0 20px 20px" : "20px 0 20px 24px" }}>
              {dealAnalysis.businessOverviewForm.map((i) => (
                <Item
                  name={i.label}
                  key={uuidv4()}
                  value={`$${numeral(i.value).format("0,0")} ${i.notes}`}
                  isMobile={isMobile}
                />
              ))}
            </Grid>
            <MDBox px={isMobile ? 2 : 3} pt={3}>
              <MDTypography variant="h6" fontWeight="medium">
                Deal Overview
              </MDTypography>
            </MDBox>
            <Grid item xs={12} sx={{ margin: isMobile ? "20px 0 20px 20px" : "20px 0 20px 24px" }}>
              {dealAnalysis.dealOverviewForm.map((i) => (
                <Item
                  name={i.label}
                  key={uuidv4()}
                  value={`${
                    [
                      "socialMediaFollowing",
                      "aksMultiple",
                      "customerDbTotal",
                      "prospectDbTotal",
                    ].includes(i.key)
                      ? numeral(i.value).format(i.key === "aksMultiple" ? "0,0.00" : "0,0")
                      : i.key === "underManagement"
                      ? i.yesOrNo === null
                        ? ""
                        : yesOrNo[i.yesOrNo]
                      : ["capacity"].includes(i.key)
                      ? i.value
                      : i.key === "reasonsForSelling"
                      ? ""
                      : `$${numeral(i.value).format("0,0")}`
                  } ${i.notes}`}
                  isMobile={isMobile}
                />
              ))}
              <Item name="Deal Notes" value={dealAnalysis.dealNotes} isMobile={isMobile} />
            </Grid>
          </ZebraPrintPWarp>

          <MDBox px={isMobile ? 2 : 3} pt={3} width="100%">
            <MDTypography variant="h5" fontWeight="regular">
              Deal Bundle WorkSheet
            </MDTypography>
          </MDBox>
          <ZebraPrintBoxWarp
            component={Grid}
            item
            xs={12}
            sx={{ margin: isMobile ? "20px 0 20px 20px" : "20px 0 20px 24px" }}
          >
            <MDBox
              display="flex"
              sx={{
                flexDirection: {
                  lg: "row",
                  md: "row",
                  sm: "row",
                  xs: "column",
                },
              }}
            >
              <MDTypography variant="body2" flex={1}>
                Ask Price: ${numeral(dealBundleWorkSheet.askPrice).format("0,0")}
              </MDTypography>
              <MDTypography flex={1} variant="body2">
                {dealBundleWorkSheet.editda}
                Ebitda: ${numeral(dealBundleWorkSheet.ebitda).format("0,0")}
              </MDTypography>
              <MDTypography flex={1} variant="body2">
                Ask Multiple: {numeral(dealBundleWorkSheet.askMultiple).format("0,0.00")}
              </MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              sx={{
                flexDirection: {
                  lg: "row",
                  md: "row",
                  sm: "row",
                  xs: "column",
                },
              }}
            >
              <MDTypography flex={2} variant="body2">
                Goal Purchase Price: $
                {numeral(dealBundleWorkSheet.preClosingStrategy.goalPurchasePrice).format("0,0")}
              </MDTypography>
              <MDTypography flex={1} variant="body2">
                Goal Multiple:{" "}
                {numeral(dealBundleWorkSheet.preClosingStrategy.goalMultiple).format("0,0.00")}
              </MDTypography>
            </MDBox>
          </ZebraPrintBoxWarp>

          <MDBox px={isMobile ? 2 : 3} pt={3}>
            <MDTypography variant="h6" fontWeight="regular" color="green">
              Pre-Closing Strategy
            </MDTypography>
          </MDBox>
          <ZebraPrintBoxWarp
            component={Grid}
            item
            xs={12}
            sx={{ margin: isMobile ? "20px 0 20px 20px" : "20px 0 20px 24px" }}
          >
            {preClosingStrategyTable.map((i) => (
              <MDTypography variant="body2" key={uuidv4()}>
                {i.label}: ${numeral(i.value).format("0,0")}
              </MDTypography>
            ))}
            <MDTypography variant="body2">
              Total Cash Impact: $
              {numeral(calculateTotal(preClosingStrategyTable, ["Y", "N"])).format("0,0")}
            </MDTypography>
          </ZebraPrintBoxWarp>

          <MDBox px={isMobile ? 2 : 3} pt={3}>
            <MDTypography variant="h6" fontWeight="regular" color="green">
              Gap Funding Strategy
            </MDTypography>
          </MDBox>
          <ZebraPrintBoxWarp
            component={Grid}
            item
            xs={12}
            sx={{ margin: isMobile ? "20px 0 20px 20px" : "20px 0 20px 24px" }}
          >
            <MDBox display="flex" sx={{ flexDirection: isMobile ? "column" : "row" }}>
              <MDTypography flex={1} variant="body2">
                Goal Purchase Price: $
                {numeral(dealBundleWorkSheet.gapFundingStrategy.goalPurchasePrice).format("0,0")}
              </MDTypography>
              <MDTypography flex={1} variant="body2">
                Deal Bundle Value: $
                {numeral(dealBundleWorkSheet.gapFundingStrategy.dealBundleValue).format("0,0")}
              </MDTypography>
              <MDTypography flex={1} variant="body2">
                Gap Left: ${numeral(dealBundleWorkSheet.gapFundingStrategy.gapLeft).format("0,0")}
              </MDTypography>
            </MDBox>
          </ZebraPrintBoxWarp>
          <ZebraPrintBoxWarp
            component={Grid}
            item
            xs={12}
            sx={{ margin: isMobile ? "20px 0 20px 20px" : "20px 0 20px 24px" }}
          >
            {gapFundingStrategyTable.map((i) => (
              <MDTypography variant="body2" key={uuidv4()}>
                {i.label}: ${numeral(i.value).format("0,0")}
              </MDTypography>
            ))}
            <MDTypography variant="body2">
              Total Gap Funded: $
              {/* {numeral(
                dealBundleWorkSheet.askPrice -
                  calculateTotal(preClosingStrategyTable) -
                  calculateTotal(gapFundingStrategyTable)
              ).format("0,0")} */}
              {numeral(calculateTotal(gapFundingStrategyTable, ["Y", "N"])).format("0,0")}
            </MDTypography>
          </ZebraPrintBoxWarp>
          <MDBox px={isMobile ? 2 : 3} pt={3}>
            <MDTypography variant="h6" fontWeight="regular" color="green">
              Post-Closing Strategy
            </MDTypography>
          </MDBox>
          <ZebraPrintBoxWarp
            component={Grid}
            item
            xs={12}
            sx={{ margin: isMobile ? "20px 0 20px 20px" : "20px 0 20px 24px" }}
          >
            {postClosingStrategyTable.map((i) => (
              <MDTypography variant="body2" key={uuidv4()}>
                {i.label}: ${numeral(i.value).format("0,0")}
              </MDTypography>
            ))}
            <MDTypography variant="body2">
              Total Cash Impact: $
              {numeral(calculateTotal(postClosingStrategyTable, ["Y", "N"])).format("0,0")}
            </MDTypography>
          </ZebraPrintBoxWarp>

          <MDBox px={isMobile ? 2 : 3} pt={3}>
            <MDTypography variant="h6" fontWeight="regular" color="green">
              Empire Impact
            </MDTypography>
          </MDBox>
          <ZebraPrintBoxWarp
            component={Grid}
            item
            xs={12}
            sx={{ margin: isMobile ? "20px 0 20px 20px" : "20px 0 20px 24px" }}
          >
            <MDBox display="flex">
              {/* <MDTypography flex={1} variant="body2">
                Business Impact: {dealBundleWorkSheet.dealNotes.bizImpact}
              </MDTypography> */}
              <MDTypography flex={1} variant="body2">
                {"Net Value Added: "}$
                {numeral(dealBundleWorkSheet.dealNotes.netValueAdded).format("0,0")}
              </MDTypography>
            </MDBox>
            <MDBox display="flex">
              <MDTypography flex={1} variant="body2">
                {"Annual Positive Cash Added: "}$
                {numeral(dealBundleWorkSheet.dealNotes.annualPositiveCashAdded).format("0,0")}
              </MDTypography>
              {/* <MDTypography flex={1} variant="body2">
                Fully Funded or Partially Funded: $
                {dealBundleWorkSheet.dealNotes.fullyFundedOrPartiallyFunded}
              </MDTypography> */}
            </MDBox>
            <MDTypography variant="body2">
              Deal Notes: {dealBundleWorkSheet.dealNotes.notes}
            </MDTypography>
          </ZebraPrintBoxWarp>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Summery;
