/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components

import React, { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import { Stack } from "@mui/material";
import MDButton from "components/MDButton";
import { useFlatInject } from "store";
import { Message } from "utils/message";
import { useLocation, useNavigate } from "react-router-dom";
import MDDropzone from "components/MDDropzone";
import { getUserId, getToken } from "utils/auth";
import { downLoadFile } from "http/downloadFile";
import { from } from "stylis";
import { useHttp, useLoading } from "utils/hooks";
import { useForm, Form, FormItem } from "../../../../../components/Form";
import DragDropFileUpload from "./components/DragDropFileUpload";

function FileUpload({ isMobile }) {
  const [
    {
      finalTermSheet,
      saveFinalTermSheet,
      fileList,
      addFile,
      deleteFile,
      addFileThroughAWS,
      deleteFileThroughAWS,
      dealReview,
    },
  ] = useFlatInject("dealPlanOperation");

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Card id="file-upload" sx={{ overflow: "visible", boxShadow: isMobile ? "none" : null }}>
      {!isMobile && (
        <MDBox p={3}>
          <MDTypography variant="h4" fontWeight="regular">
            Upload files
          </MDTypography>
        </MDBox>
      )}
      <MDBox pb={3} px={isMobile ? 0 : 3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {fileList.map((file) => (
              <MDBox key={file.name}>
                <a href={file.url} download={file.name}>
                  {file.name}
                </a>
                <MDButton
                  iconOnly
                  alt="delete"
                  onClick={() => {
                    console.log("sssss");
                    deleteFileThroughAWS(file.name);
                  }}
                >
                  <DeleteIcon />
                </MDButton>
              </MDBox>
            ))}
          </Grid>
          <Grid item xs={12}>
            <DragDropFileUpload fileList={fileList} />
          </Grid>
          <Grid item xs={12} textAlign="right">
            <MDButton
              color="green"
              variant="outlined"
              onClick={() => navigate("/deal-planner", { replace: true })}
              sx={{ mr: 1, fontSize: "14px" }}
            >
              Cancel
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default FileUpload;

// todo: complete the file delete function
async function DeleteButton(fileName, dealId) {
  const [{ deleteFileThroughAWS }] = useFlatInject("dealPlanOperation");
  await deleteFileThroughAWS(dealId, fileName);
}
