import { useMediaQuery } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:480px)");
  const redirectPolicy = () => {
    navigate("/policy-page");
  };

  const redirectTerms = () => {
    navigate("/terms-conditions-page");
  };
  const redirectFaq = () => {
    navigate("/faq-page");
  };

  const redirectContact = () => {
    navigate("/contact");
  };
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isMobile ? (
        <MDBox
          sx={{
            backgroundColor: "transparent",
            display: "flex",
            gap: "20px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "46px",
            flexDirection: "column",
            marginTop: "30px",
            map: "32px",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <MDBox
            display="flex"
            flexDirection="row"
            sx={{
              gap: "20px",
              margin: "auto",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <MDTypography
              onClick={redirectFaq}
              sx={{ fontSize: "12px", color: "#282828", cursor: "pointer" }}
            >
              FAQs
            </MDTypography>

            <MDTypography
              onClick={redirectContact}
              sx={{ fontSize: "12px", color: "#282828", cursor: "pointer" }}
            >
              Contact
            </MDTypography>
            <MDTypography
              onClick={redirectTerms}
              sx={{ fontSize: "12px", color: "#282828", cursor: "pointer" }}
            >
              Terms & Conditions
            </MDTypography>

            <MDTypography
              onClick={redirectPolicy}
              sx={{ fontSize: "12px", color: "#282828", cursor: "pointer" }}
            >
              Privacy Policy
            </MDTypography>
          </MDBox>
          <MDTypography sx={{ fontSize: "12px", color: "#282828" }}>
            2024 Empire Intelligence Pty Ltd.
          </MDTypography>
        </MDBox>
      ) : (
        <MDBox
          sx={{
            backgroundColor: "transparent",
            display: "flex",
            gap: "20px",
            paddingRight: "64px",
            paddingBottom: "46px",
            flexDirection: "row-reverse",
            marginTop: "16px",
          }}
        >
          <MDTypography sx={{ fontSize: "12px", color: "#282828" }}>
            2024 Empire Intelligence Pty Ltd.
          </MDTypography>
          <MDTypography
            onClick={redirectPolicy}
            sx={{ fontSize: "12px", color: "#282828", cursor: "pointer" }}
          >
            Privacy Policy
          </MDTypography>
          <MDTypography
            onClick={redirectTerms}
            sx={{ fontSize: "12px", color: "#282828", cursor: "pointer" }}
          >
            Terms & Conditions
          </MDTypography>
          <MDTypography
            onClick={redirectContact}
            sx={{ fontSize: "12px", color: "#282828", cursor: "pointer" }}
          >
            Contact
          </MDTypography>
          <MDTypography
            onClick={redirectFaq}
            sx={{ fontSize: "12px", color: "#282828", cursor: "pointer" }}
          >
            FAQs
          </MDTypography>
        </MDBox>
      )}
    </>
  );
}

export default Footer;
