/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// react-router-dom components
import { NavLink, useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import List from "@mui/material/List";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import SidenavItem from "examples/Sidenav/SidenavItem";
import SidenavList from "examples/Sidenav/SidenavList";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Otis Admin PRO React context
import LeftArrow from "assets/images/left-arrow-dark.svg";
import RightArrow from "assets/images/right-arrow-dark.svg";
import BrandingLogoThumb from "assets/images/small-logos/branding-logo-thumbnail.svg";
import BrandingLogo from "assets/images/small-logos/branding-logo.svg";
import { useFlatInject } from "store";
import { useMaterialUIController } from "utils/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import Settings from "pages/settings";
import { Modal } from "utils/modal";
import UserIcon from "assets/images/small-logos/user.svg";
import UserIconActive from "assets/images/small-logos/user-active.svg";
import LogoutIcon from "assets/images/small-logos/logout.svg";
import CompanySearchIcon from "assets/images/small-logos/company-search.svg";
import HeaderSideNavMobile from "./components/SidenavHeader";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const isMobile = useMediaQuery("(max-width:480px)");
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [titleHeader, setTitleHeader] = useState("Deal Planner");
  const [user] = useFlatInject("user");
  const [controller, { setMiniSidenav, setTransparentSidenav, setWhiteSidenav }] =
    useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  const COMPANY_SEARCH_URL = `${process.env.REACT_APP_COMPANY_SEARCH_URL}?token=TOKEN_${user?.user?.id}`;

  const navigate = useNavigate();

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }
  const closeSidenav = () => setMiniSidenav(true);
  const openSidenav = () => setMiniSidenav(false);

  // logout ui

  const logout = async () => {
    await Modal.confirm({
      title: "Warning",
      content: "Are your sure to logout?",
    });
    await user?.logout();
    window.localStorage.clear();
    navigate("/sign-in");
  };

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(window.innerWidth < 1200);
      setTransparentSidenav(window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested isMobile={isMobile} />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested isMobile={isMobile} />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key, iconInactive, iconActive, action, url }) => {
      let returnValue;
      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            isMobile={isMobile}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else if (url) {
        returnValue = (
          <Link
            href={url}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem
              color={color}
              isMobile={isMobile}
              iconInactive={iconInactive}
              iconActive={iconActive}
              name={name}
              active={key === itemName}
              setTitle={setTitleHeader}
              onClick={() => {
                setTitleHeader(name);
              }}
            />
          </Link>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem
              color={color}
              name={name}
              isMobile={isMobile}
              active={key === itemName}
              setTitle={setTitleHeader}
              onClick={() => setTitleHeader(name)}
            />
          </Link>
        ) : action ? (
          <SidenavItem
            key={key}
            color={color}
            isMobile={isMobile}
            name={name}
            iconInactive={iconInactive}
            iconActive={iconActive}
            active={key === itemName}
            setTitle={setTitleHeader}
            onClick={() => {
              setTitleHeader(name);
              action();
            }}
          />
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem
              color={color}
              isMobile={isMobile}
              iconInactive={iconInactive}
              iconActive={iconActive}
              name={name}
              active={key === itemName}
              setTitle={setTitleHeader}
              onClick={() => {
                setTitleHeader(name);
              }}
            />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map((route) => {
    const { type, name, icon, title, collapse, noCollapse, key, href } = route;
    let returnValue;
    if (type === "collapse-nav") {
      let collapseTransform = [...collapse];

      const vip = user?.user?.vip || 0;
      if (vip) {
        collapseTransform.push({
          name: "Company Search",
          key: "company-search",
          route: "/deal-strategies",
          url: COMPANY_SEARCH_URL,
          iconInactive: CompanySearchIcon,
          iconActive: CompanySearchIcon,
        });
      } else {
        collapseTransform.push({
          name: "Company Search",
          key: "company-search",
          route: "/deal-planner#upgrade-subscription",
          iconInactive: CompanySearchIcon,
          iconActive: CompanySearchIcon,
        });
      }

      if (isMobile && key === "deal-planner-menu") {
        collapseTransform = [...collapseTransform].concat([
          {
            name: "My Profile",
            key: "my-profile",
            route: "/my-profile",
            iconInactive: UserIcon,
            iconActive: UserIconActive,
            component: <Settings />,
          },
          {
            name: "Logout",
            key: "logout",
            iconInactive: LogoutIcon,
            iconActive: LogoutIcon,
            action: logout,
          },
        ]);
      }
      returnValue = renderCollapse(collapseTransform);
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }
    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      {isMobile ? null : !miniSidenav ? (
        <MDBox
          onClick={closeSidenav}
          component="img"
          src={miniSidenav ? RightArrow : LeftArrow}
          sx={{
            width: "32px",
            padding: "8px",
            borderRadius: "50%",
            background: "white",
            position: "absolute",
            top: "18px",
            right: "-16px",
            zIndex: 100,
            cursor: "pointer",
          }}
        />
      ) : (
        <MDBox
          onClick={openSidenav}
          component="img"
          src={LeftArrow}
          sx={{
            width: "32px",
            padding: "8px",
            borderRadius: "50%",
            background: "white",
            position: "absolute",
            top: "18px",
            right: "-16px",
            zIndex: 100,
            cursor: "pointer",
            transform: "rotate(180deg)",
          }}
        />
      )}
      <MDBox pt={3} pb={2} px={3} textAlign="center" sx={{ zIndex: 10 }}>
        {isMobile ? (
          <HeaderSideNavMobile title={titleHeader} closeSidenav={closeSidenav} />
        ) : (
          <MDBox component={NavLink} to="/" display="flex" alignItems="center">
            {!miniSidenav ? (
              <MDBox
                width={!brandName && "100%"}
                sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
                mt={3}
                mb={4}
              >
                <MDBox component="img" src={BrandingLogo} sx={{ width: "170px" }} />
              </MDBox>
            ) : (
              <MDBox mt={3} mb={4} component="img" src={BrandingLogoThumb} height="32px" />
            )}
          </MDBox>
        )}
      </MDBox>
      <List>{renderRoutes}</List>
      {isMobile ? (
        <MDBox sx={{ alignSelf: "center", position: "absolute", bottom: "28px" }} mt={3} mb={4}>
          <MDBox component="img" sx={{ width: "200px" }} src={BrandingLogo} />
        </MDBox>
      ) : null}
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.instanceOf(Array).isRequired,
};

export default Sidenav;
