// react and Data

// Otis Admin PRO React components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// mui native components
import { Grid, Stack } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  display: "flex",
  p: 4,
  minWidth: "320px",
};

function ModalUpdate({
  open,
  setOpen,
  title,
  content,
  confirmFunction,
  field,
  confirmText = "Confirm Upgrade",
  cancelText = "Cancel Upgrade",
  isSendEmailModal = false,
  onClose = () => {},
}) {
  const handleClose = () => {
    if (field) {
      setOpen((prev) => ({ ...prev, [field]: false }));
      onClose();
    } else {
      setOpen(false);
    }
  };

  const handleSubmit = async () => {
    try {
      confirmFunction();
    } catch (er) {
      console.log(er);
    } finally {
      handleClose();
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      disableScrollLock="true"
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{
        maxWidth: "100vw",
        zIndex: "1000000",
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            ...style,
            minWidth: isSendEmailModal
              ? {
                  xs: "100vw",
                  md: "90vw",
                }
              : style.minWidth,
            maxHeight: isSendEmailModal ? "80vh" : "auto",
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={isSendEmailModal ? "0" : "50px"}
          >
            <Stack justifyContent="center" sx={{ height: isSendEmailModal ? "100%" : "auto" }}>
              <Box id="transition-modal-title" variant="h6" component="h2">
                {title}
              </Box>
              <Box
                id="transition-modal-description"
                sx={{
                  mt: 2,
                  fontSize: "1rem",
                  height: isSendEmailModal ? "100%" : "auto",
                  overflow: "auto",
                }}
              >
                {content}
                <Grid
                  mt={isSendEmailModal ? { xs: 0, md: 0 } : { xs: 2, md: 8 }}
                  spacing={2}
                  container
                >
                  <Grid item xs={12} lg={6} display="flex">
                    <MDButton
                      color="green"
                      onClick={handleSubmit}
                      sx={{
                        width: { xs: "100%", lg: "auto" },
                        marginLeft: "auto",
                        minWidth: "200px",
                      }}
                    >
                      {confirmText}
                    </MDButton>
                  </Grid>

                  <Grid item xs={12} lg={6} display="flex">
                    <MDButton
                      color="green"
                      variant="outlined"
                      onClick={handleClose}
                      sx={{
                        width: { xs: "100%", lg: "auto" },
                        marginRight: "auto",
                        minWidth: "200px",
                      }}
                    >
                      {cancelText}
                    </MDButton>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ModalUpdate;
