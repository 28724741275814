import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { ErrorMessage, useField, useFormikContext } from "formik";
import React, { useState } from "react";

import {
  gapFundingStrategies,
  postCloseStrategies,
  preCloseStrategies,
} from "../../../../../constants/strategies";

const strategiesMap = {
  preCloseStrategies,
  postCloseStrategies,
  gapFundingStrategies,
};

const labelMap = {
  preCloseStrategies: "Pre-closing Strategies",
  postCloseStrategies: "Post-closing Strategies",
  gapFundingStrategies: "Gap Funding Strategies",
};

export default function StrategiesSelect({
  name,
  type = "preCloseStrategies",
  sx,
  setMargin,
  ref,
  isMobile,
  isSmallMobile,
}) {
  const [field] = useField(name);
  const [value, setValue] = useState("");
  const { setFieldValue } = useFormikContext();
  React.useEffect(() => {
    if (field?.value?.value === "OTHER") {
      setMargin?.("0px");
    }
  }, [field?.value?.value]);
  return (
    <>
      <Autocomplete
        ref={ref}
        sx={sx}
        name={name}
        getOptionLabel={(option) => option?.value || ""}
        renderInput={(params) => (
          <MDInput
            {...params}
            name={name}
            variant="standard"
            label={labelMap[type]}
            isSmallMobile={isSmallMobile}
            isMobile={isMobile}
            isValue={!!value}
          />
        )}
        value={field.value}
        onChange={(_, v) => {
          setFieldValue(name, v);
          setValue(v);
        }}
        options={strategiesMap[type]}
        renderOption={(props, option, state) => (
          <MDBox {...props} flexWrap="wrap">
            <MDBox width="100%">{option.label}</MDBox>
            <MDBox fontSize={8} color="secondary">
              {option.description}
            </MDBox>
          </MDBox>
        )}
      />
      {field?.value?.value === "OTHER" && (
        <MDInput
          autoFocus
          value={field.value.inputText}
          placeholder="Enter strategy"
          onChange={(e) => setFieldValue(name, { ...field.value, inputText: e.target.value })}
          sx={{ marginTop: "16px", width: "100%" }}
        />
      )}
      <MDBox color="error.main" fontSize={14}>
        <ErrorMessage name={name} />
      </MDBox>
    </>
  );
}
